import React from 'react';
import Layout from '../components/Layout';
import Container from '../components/Container';

const SchoolPage = () => (
  <Layout>
    <Container>
      <h1>För fristående skolor</h1>
      <p>
        Vår ambition är att bygga en skolvalsplattform där alla huvudmän ingår
        på lika villkor. Under de två senaste åren har vi utvecklat en plattform
        som flera kommuner har använt med väldigt goda resultat. Nu håller vi på
        att bygga ut denna plattform så att även fristående skolor ska kunna
        använda den, oavsett om kommunen man är belägen i använder plattformen
        eller ej. För fristående skolor kommer plattformen, bland annat, att
        erbjuda:
      </p>
      <ol>
        <li>Billig, smidig och pålitlig köhantering. </li>
        <li>
          Möjlighet att enkelt och automatiskt skicka ut erbjudanden om platser
          via brev, e-post och sms.{' '}
        </li>
        <li>
          Möjlighet för vårdnadshavare att svara på erbjudanden elektroniskt med
          BankID.{' '}
        </li>
        <li>
          Möjlighet att se dubbelplacerade elever (på andra skolor som är
          anslutna till plattformen) och därmed kunna kontakta dem för att
          försäkra sig om att de vill ha platsen de har tackat ja till.{' '}
        </li>
        <li>
          Möjlighet att enkelt integrera med ansluta till kommunernas skolval.{' '}
        </li>
      </ol>
      <p>
        Om ni är intresserade av vår plattform eller bara vill diskutera ert
        skolval är ni välkomna att höra av er till oss. Lättast når ni oss på
        hello@mittskolval.se.
      </p>
    </Container>
  </Layout>
);

export default SchoolPage;
